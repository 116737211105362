<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{ formFields.id ? `Edit ${ operationTitle }` : `Add ${ operationTitle }` }}
          </template>
          <template v-slot:toolbar>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="users-operation">
                <b-container fluid>
                  <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                    <b-row>
                      <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                              :invalid-feedback="formErrors.first('name')"
                              label="Full Name *"
                              label-for="name"
                            >
                              <b-form-input
                                :state="((formErrors.has('name') ? false : null))"
                                @focus="$event.target.select()"
                                id="name"
                                ref="name"
                                type="text"
                                v-model="formFields.name"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                              :invalid-feedback="formErrors.first('email')"
                              label="Email"
                              label-for="email">
                              <b-form-input
                                :state="((formErrors.has('email') ? false : null))"
                                @focus="$event.target.select()"
                                id="email"
                                type="email"
                                v-model="formFields.email"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row><!--/b-row-->
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                              :invalid-feedback="formErrors.first('phone')"
                              label="Phone *"
                              label-for="phone">
                              <b-form-input
                                :state="((formErrors.has('phone') ? false : null))"
                                @focus="$event.target.select()"
                                id="phone"
                                type="text"
                                v-model="formFields.phone"
                                v-numericOnly.keyup
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="4" v-if="formFields.id">
                            <b-form-group
                              :invalid-feedback="formErrors.first('hall_id')"
                              label="Hall"
                              label-for="hall_id"
                            >
                              <treeselect
                                :class="[{'invalid is-invalid': (formErrors.has('hall_id'))}]"
                                :options="dropdowns.halls"
                                @input="handleChangeHalls"
                                id="Hall"
                                label="hall_id"
                                v-model="formFields.hall_id"
                              />
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                              label="Profile"
                              label-for="profile">
                              <b-form-file
                                @change="onChangeFile"
                                id="profile">
                              </b-form-file>
                              <img
                                :src="getFile.download_url"
                                class="img-responsive mt-1"
                                height="100"
                                v-if="getFile && getFile.download_url" width="100" />
                            </b-form-group>
                          </b-col>
                        </b-row><!--/b-row-->
                        <b-row v-if="formFields.hall_id !== null">

                          <b-col sm="12">
                            <b-form-checkbox
                              @change="handleChangePermission"
                              v-model="selectAllPermission">
                              Select All
                            </b-form-checkbox>
                            <hr />
                          </b-col>

                          <b-col :key="index"
                                 sm="4"
                                 v-for="(permission, index) in formFields.permissions">
                            <b-form-checkbox
                              :unchecked-value="false"
                              :value="true"
                              v-model="permission.checked"
                            >
                              {{ permission.name }}
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <hr />
                        <b-row class="operation-footer">
                          <b-col sm="12">
                            <b-button
                              :disabled="global.pendingRequests > 0"
                              size="sm"
                              type="submit"
                              variant="primary"
                            >
                              <i class="fa fa-spin fa-spinner"
                                 v-show="global.pendingRequests > 0"></i>
                              <i class="fa fa-save fa-sm"></i>
                              Save
                            </b-button>

                            <b-button
                              @click="handleOperationClose(); resetPermissions()"
                              class="ml-3"
                              size="sm"
                              variant="danger">
                              <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
              </div><!--/.users-operation-->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import moduleMixin from "./module.mixin";
import { mapState } from "vuex";

export default {
  mixins: [moduleMixin],
  components: {
    KTCard,
  },
  mounted() {
    this.getPermissions();

    if ( this.$route.params && this.$route.params.id ) {
      this.handleEditOperation(this.$route.params.id);
    }
  },
  computed: {
    ...mapState([
      'global',
    ]),
  },
};
</script>
