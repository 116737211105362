import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { authUserPermission } from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
  name: null,
  email: null,
  profile: {},
  permissions: [],
  phone: null,
  property_id: null,
  hall_id: null,
  _method: 'post',
};

const DEFAULT_FILTER_STATE = {
  name: null,
  email: null,
  phone: null,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name",
    sortable: false,
    value: 'name',
  },
  {
    text: "Permissions",
    sortable: false,
    value: 'permissions',
  },
  {
    text: "Email",
    sortable: false,
    value: 'email',
  },
  {
    text: "Phone",
    sortable: false,
    value: 'phone',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  data() {
    return {
      operationTitle: 'Sub Users',
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      filters: { ...DEFAULT_FILTER_STATE },
      listUrl: '/sub/users',
      columns: COLUMN_DEFINATION(this),
      deleteId: null,
      permissions: [],
      dropdowns: {
        halls: [],
        superUsers: [],
      },
      selectAllPermission: [],
      file: {}
    };
  },
  methods: {
    handleChangePermission() {
      if ( this.selectAllPermission ) {
        this.formFields.permissions = _.map(authUserPermission(), (i) => {
          return {
            id: i.id,
            permission: i.permission,
            name: i.name,
            checked: true
          };
        });
      } else {
        this.formFields.permissions = [];
      }

    },
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let self = this;
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
       return data;

      } catch (error) {
      }
    },
    afterCloseOperation() {
      this.formFields = { ...DEFAULT_FORM_STATE };
    },
    handleChangeOperations(operation = 'add', operationDataId = null) {
      this.selectAllPermission = false;
      this.$router.replace({
        query: Object.assign({},
          this.$route.query,
          {
            ...this.listQueryParams,
            operation: operation,
            id: operationDataId,
          },
        ),
      });
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      let self = this;

      self.formFields.property_id = this.globalPropertyId;
      self.formFields.profile = this.getFile

      try {
        const response = await request({
          url: this.formFields.id ? 'sub/users/update' : 'sub/users/create',
          method: 'post',
          data: {
            ...this.formFields,
            permissions: this.formFields.permissions.filter((item) => item.checked).map((item) => {
              return item.id;
            }),
          },
        });
        const { data } = response.data;

        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/sub/users/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/sub/users/detail/${ id }`,
        });
        const { data } = response.data;

        this.formFields = {
          ...data,
          hall_id: data.hall ? data.hall.id : null,
        };

        this.file = data.profile_pic;
        this.getHalls(this.formFields.id);
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    resetPermissions() {
      this.formFields.permissions = [];
    },
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.checkFilterCountStatus();
    },
    async getHalls(userId) {
      try {
        const response = await request({
          url: `/dropdowns/sub/user/halls/${ userId }`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.halls = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });

        this.handleChangeHalls();
      } catch (e) {

      }
    },
    async getPermissions() {
      this.permissions = _.map(this.$global.authUserPermission(), (item) => {
        return {
          id: item.id,
          name: item.name,
          checked: false
        };
      });
    },
    async handleChangeHalls() {
      this.permissions = _.map(this.permissions, (item) => {
        return {
          id: item.id,
          name: item.name,
          checked: false
        };
      });
      if ( this.formFields.id && this.formFields.hall_id ) {
        try {
          const response = await request({
            url: `/dropdowns/hall/permissions/${ this.formFields.hall_id }/${ this.formFields.id }`,
            method: 'post',
          });

          const { data } = response.data;
          this.formFields.permissions = this.permissions;

          if ( data ) {
            const matchedIndexes = data
            .map((item) => this.formFields.permissions.findIndex((subkey) => subkey.id === item.id));

            matchedIndexes.map((item, index) => {
              this.formFields.permissions[item].checked = true;
            });
          }

          this.formFields.permissions = this.formFields.permissions;
        } catch (e) {

        }
      }
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "subuserList" });
    },
  },
  computed: {
    getFile() {
      return this.file;
    }
  }
};
